module.exports = [{
      plugin: require('/Users/zafidlle/git/borealis/osmium/studio-blog/node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://borealisdigitalstudio.matomo.cloud/","siteUrl":"https://borealisdigitalstudio.com/","disableCookies":true},
    },{
      plugin: require('/Users/zafidlle/git/borealis/osmium/studio-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/zafidlle/git/borealis/osmium/studio-blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/zafidlle/git/borealis/osmium/studio-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
